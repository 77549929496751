/**********************
Global Styles
**********************/
*,
*:after,
*:before {
  box-sizing: border-box; }

/** Fixing bootstrap columns for safari **/
.row:after,
.row:before {
  display: inline-block !important; }

body {
  color: #000;
  font-size: 17px;
  line-height: 1.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sofia-pro, sans-serif;
  letter-spacing: 0;
  overflow-x: hidden; }

button {
  background-color: transparent;
  border: 0; }
  button:hover {
    cursor: pointer; }
  button:focus {
    outline: 0; }

a,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0; }

section {
  padding: 80px 0; }

.container {
  max-width: 1140px; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto; }

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.section-title {
  font-size: 38px;
  color: #000;
  font-weight: 700; }

.sub-heading {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  display: inline-block;
  text-transform: uppercase;
  color: #86807a;
  margin-bottom: 12px;
  font-weight: 600; }

strike {
  color: rgba(136, 136, 136, 0.7); }

.img-container {
  text-align: center; }

.section-header {
  margin-bottom: 30px; }

.sale-text {
  font-weight: 700;
  font-size: 50px;
  line-height: 1;
  margin: 20px 0 30px;
  color: #000; }
  .sale-text span {
    text-decoration: line-through;
    color: #c9c9c9;
    font-weight: 400; }

.modal {
  background-color: rgba(0, 0, 0, 0.8); }

/* Buttons */
.btn-prime,
.btn-prime:link,
.btn-prime:visited {
  padding: 10px 25px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  vertical-align: middle;
  box-shadow: none !important;
  outline: 0;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px; }

.btn-prime:hover {
  opacity: 0.9; }

.btn-prime-dark,
.btn-prime-dark:focus,
.btn-prime-dark:hover {
  border: 2px solid #000;
  background-color: #000;
  color: #fff; }

.btn-prime-border,
.btn-prime-border:focus,
.btn-prime-border:hover {
  background-color: #db3e37;
  border: 2px solid #db3e37;
  color: #fff; }

.flex-container {
  -webkit-box-align: center;
  align-items: center; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto; }

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.link-color {
  color: #db3e37;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700; }
  .link-color:hover {
    color: #db3e37;
    opacity: 0.8; }

@media only screen and (max-width: 767px) {
  section {
    padding: 60px 0; }
  .sub-heading {
    font-size: 17px; } }

@media only screen and (max-width: 600px) {
  .section-title {
    font-size: 25px; } }

.slick-dots li button:before {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-image: url("../img/dot-border.png");
  background-repeat: no-repeat;
  content: "";
  opacity: 1; }

.slick-dots li.slick-active button:before {
  background-image: url("../img/dot-full.png");
  background-repeat: no-repeat; }

.slick-list {
  margin: 0 -30px; }

.slick-slide {
  margin: 0 30px; }

.slick-track {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer; }

/**********************
Navigation
**********************/
header {
  background-color: #fff;
  box-shadow: none;
  height: 70px; }
  header .container {
    height: 100%; }
  header #main-nav {
    padding: 10px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 100%; }
    header #main-nav ul.menu-list {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none; }
  header .navbar-dark .navbar-nav .nav-link:hover {
    background-color: transparent;
    color: #db3e37; }
  header .navbar-dark .navbar-brand img {
    height: 48px; }
  header .buy-now {
    background-color: #000 !important;
    color: #fff !important;
    text-transform: uppercase; }
  @media (max-width: 991px) {
    header .navbar-dark .navbar-brand img {
      height: 40px; } }

.buy-now {
  background-color: #db3e37 !important;
  color: #fff !important; }

.nav-link,
.nav-link:active,
.nav-link:focus,
.nav-link:hover,
.nav-link:visited {
  color: #000;
  font-size: 14px;
  outline: 0;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding: 10px 15px !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .nav-link:hover,
  .nav-link:active:hover,
  .nav-link:focus:hover,
  .nav-link:hover:hover,
  .nav-link:visited:hover {
    color: #db3e37; }

.navbar-dark .navbar-brand {
  font-weight: 700;
  color: #db3e37;
  height: 100%;
  padding: 0; }

.navbar-brand {
  max-width: 300px;
  padding: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center; }

.navbar-dark .navbar-brand img {
  height: 70px; }

.navbar-nav .dropdown-menu {
  left: auto;
  right: 0;
  border: 0;
  border-radius: 0;
  margin-top: 0; }

.dropdown-item {
  background-color: #fff; }

.dropdown-menu.show {
  background-color: #fff;
  padding: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f8f8f8;
  color: #212529; }

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #db3e37; }

.cta-btn {
  background-color: #db3e37; }

.cta-btn .nav-link {
  color: #fff !important; }

@media only screen and (max-width: 767px) {
  #main-nav {
    display: none !important; } }

/* Mobile Menu */
header {
  background-color: #fff;
  position: relative;
  box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2); }

@media screen and (min-width: 992px) {
  .mobile-menu {
    display: none; } }

@media screen and (max-width: 991px) {
  .mobile-nav .btn-prime.btn-prime-dark {
    color: #fff;
    font-size: 26px;
    width: 100%;
    text-transform: uppercase;
    text-transform: inherit; }
  input#burger {
    display: none; }
  .mobile-menu .navbar-brand {
    font-weight: 700;
    color: #db3e37; }
  .mobile-menu input:not(:checked) .mobile-links {
    display: none; }
  .mobile-menu input + label {
    position: fixed;
    top: 23px;
    right: 20px;
    height: 20px;
    width: 34px;
    z-index: 5;
    padding: 0;
    margin-bottom: 0; }
  .mobile-menu input + label span {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 60%;
    margin-top: -1px;
    left: 0;
    display: block;
    background: #000;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .mobile-menu input + label span:first-child {
    top: 4px; }
  .mobile-menu input + label span:last-child {
    top: 20px; }
  .mobile-menu label:hover {
    cursor: pointer; }
  .mobile-menu input:checked + label span {
    opacity: 0;
    top: 50%; }
  .mobile-menu input:checked + label span:first-child {
    opacity: 1;
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); }
  .mobile-menu input:checked + label span:last-child {
    opacity: 1;
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  .mobile-menu input ~ nav {
    background: #fff;
    box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 3;
    overflow: hidden; }
  .mobile-menu nav {
    position: relative; }
  .mobile-menu input ~ nav > ul {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
    margin-bottom: 0;
    width: 80%; }
  .mobile-menu input ~ nav > ul > li {
    display: none; }
  .mobile-menu input ~ nav > ul > li > a {
    color: #000;
    font-weight: 700;
    display: block;
    padding: 12px;
    font-size: 25px; }
  .mobile-menu input:checked ~ nav {
    height: 100%;
    -webkit-transition-delay: 0s;
    transition-delay: 0s; }
  .mobile-links {
    list-style: none; }
  .mobile-menu input:checked ~ nav > ul > li {
    display: initial; }
  .mobile-menu .navbar-brand {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3000;
    height: 100%;
    padding: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
  .mobile-menu input + label {
    right: 30px; }
  .mobile-menu .navbar-brand {
    left: 15px; } }

@media screen and (max-width: 575px) {
  .mobile-menu input ~ nav > ul > li > a,
  .mobile-nav .btn-prime.btn-prime-dark {
    font-size: 17px; } }

@media (max-width: 450px) {
  .mobile-menu .navbar-brand {
    max-width: 220px; } }

.btn-prime-border strike {
  color: #f7c0bf; }

@media screen and (max-width: 375px) {
  .mobile-menu .navbar-brand {
    max-width: 170px; } }

/******************************
Hero
******************************/
.hero {
  margin-top: 70px;
  position: relative;
  padding: 0;
  color: #fff;
  /* ken burns effect */ }
  .hero .container {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    text-align: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
    .hero .container .row {
      width: 100%; }
  .hero .carousel-item {
    height: 800px;
    position: relative; }
    .hero .carousel-item::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.3; }
  .hero .ken-img {
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
  .hero .carousel .carousel-item .ken-img {
    -webkit-transition: all 40s;
    transition: all 40s; }
  .hero .carousel .carousel-item .ken-img,
  .hero .carousel .carousel-item.active.inactiveUntilOnLoad .ken-img {
    -webkit-transform: scale(1);
    transform: scale(1); }
  .hero .carousel .carousel-item.active .ken-img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5); }
  .hero .section-header {
    margin-bottom: 30px; }
    .hero .section-header .section-title {
      color: #fff;
      font-size: 64px;
      margin-bottom: 10px; }
    .hero .section-header .sub-heading {
      color: #fff; }

@media only screen and (max-width: 1199px) {
  .hero .carousel-item {
    height: 650px; } }

@media only screen and (max-width: 991px) {
  .hero .carousel-item {
    height: 440px; }
  .hero .section-header .section-title {
    font-size: 50px; } }

@media only screen and (max-width: 600px) {
  .hero .section-header {
    margin-bottom: 10px; }
    .hero .section-header .sub-heading {
      margin-top: 15px; }
  .hero .section-header .section-title {
    font-size: 38px;
    line-height: 0.8; }
  .hero .section-header .section-title span {
    display: block; } }

/**********************
Hero
**********************/
/**********************
Logo Slider
**********************/
#logos {
  background-color: #f4f4f4;
  padding: 20px 0; }

@media only screen and (max-width: 420px) {
  #logos .img-container {
    max-width: 100px;
    margin: 0 auto; } }

/**********************
Video
**********************/
.video {
  text-align: center; }
  .video p {
    font-weight: 500; }
  .video .section-header {
    margin-bottom: 20px; }
  .video .cta-container {
    margin-top: 40px; }
  .video .main-video {
    margin-bottom: 30px; }
    .video .main-video .video-container {
      position: relative;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center; }
    .video .main-video .img-container {
      position: relative; }
      .video .main-video .img-container::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.1; }
    .video .main-video .play-btn {
      position: absolute;
      max-width: 120px;
      z-index: 1; }
  .video.part-2 .section-header {
    margin-bottom: 16px; }
  .video.part-2 .container {
    background: transparent !important; }
  .video.part-2 ul {
    margin-bottom: 1rem;
    padding-left: 10px;
    list-style: none; }
    .video.part-2 ul li {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: start;
      align-items: flex-start;
      margin-bottom: 8px; }
      .video.part-2 ul li i {
        margin: 3px 7px 0 0;
        color: #db3e37; }
  .video.part-2 .cta-container {
    margin-top: 30px; }

@media only screen and (max-width: 600px) {
  .video .section-header {
    margin-bottom: 20px; }
  .video .main-video .play-btn {
    max-width: 75px; } }

/**********************
How It Works
*************************/
.how {
  background-color: #c6dee2;
  text-align: center; }
  .how .section-header {
    margin-bottom: 40px; }
    .how .section-header .sub-heading {
      color: #000;
      margin-bottom: 30px; }
  .how .icons {
    margin: 0 0 30px; }
    .how .icons .icon {
      max-width: 40%;
      margin: 0 auto 16px; }
      .how .icons .icon p {
        margin-bottom: 0; }
    .how .icons .icon-title {
      font-weight: 700;
      font-size: 24px; }
    .how .icons .icon-text {
      text-transform: uppercase; }
  .how .img-container {
    margin-bottom: 40px; }

@media only screen and (max-width: 767px) {
  .how .icon-container:not(:last-child) {
    margin-bottom: 30px; } }

/**********************
What's Inside
**********************/
.whats-inside .container {
  position: relative;
  height: 700px; }

.whats-inside .section-header {
  text-align: center;
  margin-bottom: -50px; }

.whats-inside .text-area {
  background: #fed8a3;
  padding: 50px;
  position: absolute;
  bottom: 0;
  right: 15px;
  width: 50%; }

.whats-inside .img-container {
  position: absolute;
  top: 80px;
  left: 15px;
  z-index: 1;
  max-width: 720px; }

.whats-inside .included-item {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center; }
  .whats-inside .included-item img {
    margin-right: 5px; }
  .whats-inside .included-item:not(:last-child) {
    margin-bottom: 20px; }

.whats-inside .cta-container {
  padding: 0 15px;
  margin-top: 40px;
  text-align: center; }

@media only screen and (max-width: 1199px) {
  .whats-inside .img-container {
    max-width: 660px; } }

@media only screen and (max-width: 1060px) {
  .whats-inside .img-container {
    max-width: 610px; } }

@media only screen and (max-width: 991px) {
  .whats-inside .container {
    height: auto; }
  .whats-inside .text-area {
    position: relative;
    padding: 50px 15px;
    bottom: 0;
    right: 0;
    width: 100%;
    text-align: center;
    max-width: 400px;
    margin: 0 auto; }
    .whats-inside .text-area .section-title {
      margin-bottom: 25px; }
  .whats-inside .section-header {
    margin-bottom: 30px; }
  .whats-inside .included-item {
    -webkit-box-pack: center;
    justify-content: center; }
  .whats-inside .img-container {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    margin: 0 auto 30px; }
  .whats-inside .cta-container {
    margin-top: 20px; } }

@media only screen and (max-width: 400px) {
  .whats-inside .included-item {
    -webkit-box-pack: left;
    justify-content: left;
    text-align: left; } }

/**********************
Reviews
**********************/
.reviews {
  background: #f9af9f;
  position: relative;
  z-index: 1;
  padding-top: 0;
  margin-top: 80px; }
  .reviews .flex-container {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap; }
  .reviews .section-header {
    margin-bottom: 60px;
    text-align: center; }
  .reviews .slick-track {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
  .reviews .slick-dotted.slick-slider {
    margin-bottom: 0; }
  .reviews .slick-dots {
    bottom: 0;
    position: relative;
    margin-top: 50px; }
  .reviews .slick-slide img {
    display: initial; }
  .reviews .text-area {
    margin-top: 250px; }
  .reviews .events-slider {
    margin-top: -80px; }
  .reviews .product-next,
  .reviews .product-prev {
    cursor: pointer; }
  .reviews .quote {
    font-size: 33px;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 50px; }
  .reviews .reviewer {
    font-size: 18px;
    text-align: right;
    font-weight: 700; }

@media only screen and (max-width: 991px) {
  .reviews .item .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse; }
  .reviews .item.left .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
  .reviews .item img {
    margin-bottom: 40px; }
  .reviews .text-area {
    margin-top: 0;
    max-width: 600px;
    margin: 0 auto;
    text-align: center; }
  .reviews .reviewer {
    text-align: center; }
  .reviews .slick-dots {
    margin-top: 30px; } }

@media only screen and (max-width: 600px) {
  .reviews .item img {
    margin-bottom: 30px; }
  .reviews .quote {
    font-size: 20px;
    margin-bottom: 30px; }
  .reviews .reviewer {
    font-size: 17px; }
  .reviews .slick-dots {
    margin-top: 20px; } }

/**********************
About
**********************/
.about {
  padding-bottom: 0;
  margin-bottom: -100px; }
  .about .section-header {
    text-align: center; }
  .about .text-area {
    padding-top: 60px; }

@media only screen and (max-width: 991px) {
  .about {
    padding: 80px 0;
    margin-bottom: 0;
    text-align: center; }
  .about .text-area {
    padding-top: 20px; }
  .about .section-header {
    margin-bottom: 0; } }

@media only screen and (max-width: 767px) {
  .about {
    padding: 60px 0; } }

/**********************
Purchase Options
**********************/
.purchase-options {
  text-align: center;
  background: #f4f4f4;
  padding-top: 160px; }
  .purchase-options .section-header {
    margin-bottom: 60px; }
  .purchase-options .row-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px; }
  .purchase-options .sale {
    position: absolute;
    top: -20px;
    left: -12px;
    display: inline-block;
    background: #db3e37;
    color: #fff;
    font-weight: 700;
    height: 2.5rem;
    width: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    vertical-align: middle;
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg); }
    .purchase-options .sale::after, .purchase-options .sale::before {
      content: "";
      position: absolute;
      background: inherit;
      height: inherit;
      width: inherit;
      top: 0;
      left: 0;
      z-index: -1;
      -webkit-transform: rotate(30deg);
      transform: rotate(30deg); }

.package {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1;
  text-align: center;
  position: relative; }
  .package p {
    margin-bottom: 0; }
  .package .package__header {
    padding: 0; }
    .package .package__header .img-container {
      margin-bottom: 24px; }
  .package__price {
    font-size: 22px;
    font-weight: 700;
    margin: 12px 0 20px;
    display: inline-block; }
    .package__price strike {
      font-weight: 400;
      color: #9a9a9a; }
  .package__title {
    font-size: 24px;
    color: #1d1e1f;
    padding: 0 10px;
    font-weight: 700; }
    .package__title span {
      display: block; }
  .package__body p {
    font-weight: 700;
    font-size: 15px;
    text-transform: capitalize; }
    .package__body p:not(:last-child) {
      margin-bottom: 8px; }

.package .btn-prime-dark {
  margin: auto auto 0;
  max-width: 300px; }

.purchase-options .sale::after {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg); }

@media only screen and (max-width: 991px) {
  .purchase-options {
    padding: 80px 0; }
  .package {
    margin: 0 auto;
    max-width: 500px; }
  .purchase-options .row-container {
    gap: 50px; }
  .purchase-options .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
  .purchase-options .sale {
    top: -20px;
    right: 0;
    left: initial;
    height: 4.5rem;
    width: 4.5rem;
    line-height: 4.5rem;
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
    font-size: 27px; }
  .package .package__header .img-container img {
    height: auto; } }

@media only screen and (max-width: 450px) {
  .purchase-options .row-container {
    grid-template-columns: 1fr; }
  .purchase-options .sale {
    font-size: 20px;
    height: 3.5rem;
    line-height: 3.5rem;
    width: 3.5rem; } }

.subscribe {
  background-color: #eee2d7; }
  .subscribe .section-header {
    text-align: center; }
  .subscribe form {
    max-width: 650px;
    margin: 0 auto; }

#_form_1_ {
  text-align: center; }

#_form_1_submit,
._form-content.form-area input {
  height: 45px; }

._form-content.form-area {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  background: #fff;
  padding: 10px; }

._form-content.form-area input {
  background: #fff;
  width: 100%;
  padding-left: 10px;
  border: none; }

#_form_1_submit {
  background: #242424;
  color: #fff;
  font-size: 12px;
  letter-spacing: 2px;
  padding: 12px 20px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600; }

.input-area {
  max-width: 760px;
  width: 100%; }

._error-inner {
  text-align: left;
  margin-top: 16px;
  padding: 8px 10px;
  color: red;
  background-color: #fff6f6;
  max-width: 300px; }

._form-thank-you {
  padding: 10px 20px;
  background: #fff; }

@media (max-width: 600px) {
  ._form-content.form-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
  .input-area {
    width: 100%;
    margin-bottom: 10px; }
  ._form-content.form-area input {
    border-color: #d8d7d7; }
  #_form_1_submit {
    width: 100%; } }

/**********************
Footer
**********************/
footer {
  background-color: #000;
  color: #fff;
  padding: 60px 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600; }
  footer .mail {
    font-weight: 700;
    margin-left: 10px;
    color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-transform: lowercase;
    font-size: 16px; }
  footer .copyright {
    margin-top: 7px; }
  footer .footer-content {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between; }
    footer .footer-content.footer-top {
      margin-bottom: 30px; }
      footer .footer-content.footer-top .img-container {
        max-width: 300px; }
  footer .social {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
    footer .social svg {
      margin: 0 7px;
      font-size: 18px;
      color: #fff; }

@media only screen and (max-width: 675px) {
  footer {
    text-align: center; }
  footer .footer-content.footer-top .img-container {
    max-width: 200px; }
  footer .footer-content {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
  footer .img-container {
    margin-bottom: 30px; }
  .social {
    margin-bottom: 15px; } }

/** MEDIA QUERIES **/
/******************************
Mobile - Landscape
******************************/
